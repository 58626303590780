body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-src-container {
  background-color: #f5f5f5;
  width: 98% !important;
  padding: 0px 10px;
  height: 48px;
  overflow: hidden;
  border-bottom: 1px solid rgb(208, 208, 208);
  margin: 10px 0px;
}
.img-src-container p {
  font-size: 12px;
  color: gray;
  padding: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.src {
  width: 100% !important;
  overflow: hidden;
  margin-top: -8px;
  font-size: 15px !important;
}

.TableCellGrid {
  cursor: pointer !important;
}
.TableRowGrid {
}

.TableRowGrid:hover {
  background-color: rgba(224, 224, 224, 0.645) !important;
}

.PaginationReactAdmin {
  margin-left: -92px;
  background-color: white;
  z-index: 9999;
}

.ElasticToolBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  margin-top: 10px;
  margin-bottom: -50px;
  position: relative;
  z-index: 9999999;
}

.ElasticAlert {
  position: absolute;
  z-index: 9999;
  margin: auto;
  width: 100%;
}
