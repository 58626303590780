/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  scroll-behavior: revert;
  overflow: hidden;
  overscroll-behavior: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
  scroll-behavior: revert;
  overflow: hidden;
  overscroll-behavior: none;
}
input[type='number']::-webkit-outside-spin-button {
  display: none;
  scroll-behavior: revert;
  overflow: hidden;
  overscroll-behavior: none;
}
